import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import GlobalStyle from '../styles/GlobalStyle';
import React from 'react';
import PropTypes from 'prop-types';

const RootLayout = (props) => {
    return (
        <>
            <GlobalStyle />
            <Navbar isReauthenticate={props.isReauthenticate} />
            {props.children}
            <Footer />
        </>
    );
};

RootLayout.propTypes = {
    isReauthenticate: PropTypes.bool,
    children: PropTypes.node,
};
RootLayout.defaultProps = {
    isReauthenticate: false,
    children: null,
};

export default RootLayout;
